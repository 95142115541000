.picker {
  margin-right: 10px;
}

.picker-small {
  min-width: 130px;
  width: 130px;
}

.picker-medium {
  width: 200px;
  min-width: 200px;
}

.picker-big {
  width: 350px;
  min-width: 350px;
}

.calcurator-wrapper {
  padding: 20px;
  max-width: 1140px;
  margin: 0 auto;
}

.calcurator-wrapper .title {
  font-size: 20px;
  padding-bottom: 20px;
}

.calculator-loader {
  display: flex;
  margin-top: 80px;
  justify-content: center;
}

.crossed {
  text-decoration: line-through;
  margin-right: 6px;
}

.availability-icon-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.customers-wrapper {
  display: flex;
  flex-direction: column;
}

.availability-icon {
  height: 30px;
  width: auto;
  padding: 3px 25px 3px 0;
}

.picker-row {
  display: flex;
  padding: 13px 0;
  flex-direction: row;
  position: relative;
}

.sum-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px #ececec;
  padding: 18px 0 10px 0;
}

.sum-row:last-child {
}

.additional-prices {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.additional-prices-wrapper {
  display: flex;
  flex-direction: column;
}

.additional-prices-wrapper div {
  margin: 2px 0;
}

.additional-prices-wrapper label {
  margin: 2px 0;
}

.additional-prices .price {
  margin-left: 10px;
}

.room-additional-prices {
  margin-top: 15px;
  margin-bottom: 15px;
}

.total-price .price {
  margin-left: 10px;
}

.calcurator-wrapper .title-row {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #e4e4e4;
  padding-top: 15px;
  padding-bottom: 25px;
  margin-bottom: 35px;
}

.calcurator-wrapper .address {
  display: flex;
  flex-direction: column;
}

.sendingError {
  left: 50%;
  transform: translateX(-50%);
  color: red;
  position: absolute;
  top: 15px;
}

.calcurator-wrapper .address .hotel {
  font-weight: 700;
  font-size: 20px;
  color: #103e6f;
}

.calcurator-wrapper .address .country {
  font-weight: 600;
  font-size: 15px;
  color: #333333;
}

.calcurator-wrapper .term {
  font-weight: 700;
  font-size: 20px;
  color: #103e6f;
}

.calcurator-wrapper .term span {
  font-size: 20px;
  color: #103e6f;
}
.capacity {
  max-width: 500px;
}

.capacity .availability-icon-wrapper img:last-child {
  padding-right: 0;
}

.capacity .availability-icon-wrapper img {
  height: 26px;
  padding-right: 15px;
}

.customers-wrapper {
  flex-direction: row;
}

.persons-picker {
  display: flex;
}

.occupation-type-label-wrapper {
  font-weight: 700;
  font-size: 15px;
  width: 160px;
}

.persons-picker {
  position: relative;
}

.picker {
  display: flex;
  flex-direction: column;
  position: relative;
}

.subsidy-claim-wrapper {
  width: 180px;
}

.additional-prices-wrapper {
  width: 360px;
}

.infants-info {
  position: absolute;
  top: -20px;
  left: 0;
  font-style: italic;
}

.info-text-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: -2px;
  font-weight: bold;
  margin-top: 3px;
}

@media only screen and (max-width: 768px) {
  .two-infos {
    margin-top: 70px;
  }

  .info-text-wrapper {
    flex-direction: column;
  }

  .info-text-wrapper div {
    margin-bottom: 5px;
  }

  .two-infos .infants-info {
    top: -55px;
  }
}

.info-text-wrapper div {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.info-text-wrapper i {
  color: #999999;
  margin-right: 5px;
  font-size: 22px;
}

.combination-not-allowed {
  position: absolute;
  bottom: -20px;
  left: 0;
  color: red;
}

.occupant-summary {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  padding: 0px 20px 0px;
}

.text-select-input {
  margin: 0 5px;
  border: solid 1px #e4e4e4;
  border-radius: 3px;
  padding: 2px;
}

.text-datepicker-input {
  margin: 0 5px;
  border: solid 1px #e4e4e4;
  border-radius: 3px;
  background-color: #fff;
  top: 0;
  height: 24px;
}

.text-datepicker-input div {
  width: 120px !important;
  height: inherit !important;
  line-height: normal !important;
}

.text-datepicker-input div input {
  padding: 0px 5px !important;
  font-size: 13px !important;
  color: #404040 !important;
  font-weight: 500 !important;
}

.person-price-wrapper {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-left: auto;
}

.person-price-wrapper .label {
  font-weight: 600;
}

.person-price-wrapper .price {
  font-size: 16px;
  font-weight: 700;
}

.eating-type-wrapper {
  width: 140px;
}

.custom-checkbox {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background-color: transparent;
  border: solid 1px #848484;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #edb700;
  border-color: transparent;
}

.subsidy.custom-checkbox input:checked ~ .checkmark {
  background-color: #afc836;
}

.szSubsidy.custom-checkbox input:checked ~ .checkmark {
  background-color: #FF5200;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.item:last-child {
  border-bottom: none !important;
}

.summary-info-wrapper {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.summary-info-wrapper .total-price {
  flex-direction: row;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 25px;
  color: #edb700;
}

.summary-info-wrapper .departure {
  flex-direction: row;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 15px;
  color: #edb700;
}

.summary-info-wrapper .total-price.subsidy {
  color: #afc836;
}

.summary-info-wrapper .total-price.szSubsidy {
  color: #FF5200;
}

.summary-info-wrapper .departure.szSubsidy {
  color: #FF5200;
}
.summary-info-wrapper .departure.subsidy {
  color: #afc836;
}

.summary-info-wrapper .orange-button {
  margin: 0 !important;
}

.item label {
  font-weight: 400 !important;
}

.item label,
.item input {
  cursor: pointer;
}

.agreement {
  margin-bottom: 30px;
  margin-top: 20px;
}

.customer-row-label {
  position: absolute;
  top: 7px;
  left: 5px;
  font-size: 13px;
  color: #515151;
}

@media only screen and (max-width: 768px) {
  .picker {
    width: 100% !important;
    min-width: 100% !important;
  }

  .picker-row {
    flex-direction: column;
  }

  .persons-picker {
    flex-direction: column;
  }

  .combination-not-allowed {
    display: none;
  }

  .special-adult {
    margin-top: 60px;
  }

  .special-adult .infants-info {
    top: -44px;
  }
}

@media only screen and (max-width: 1024px) {
  .calcurator-wrapper .title-row {
    flex-direction: column;
  }

  .calcurator-wrapper .term {
    margin: 10px 0;
  }

  .calcurator-wrapper .address .hotel {
    font-size: 17px;
  }

  .capacity .availability-icon-wrapper img {
    height: 20px;
    padding-right: 10px;
  }

  .picker-big {
    width: 220px;
    min-width: 220px;
  }

  .sum-row {
    flex-direction: column;
    align-items: flex-start;
    border-width: 2px;
  }

  .subsidy-claim-wrapper {
    margin-top: 10px;
  }

  .additional-prices-wrapper {
    margin-top: 15px;
    padding-top: 10px;
    width: 100%;
    border-top: 1px solid #ececec;
  }

  .eating-type-wrapper {
    margin-top: 20px;
  }
}

.select-box {
  border: solid 1px #ccc !important;
}

.reservation-input.title {
  width: 70px;
  min-width: 70px;
}

.text-input {
  border: 1px solid rgb(204, 204, 204) !important;
  display: inline-block;
  padding: 0px 0px 0px 10px;
  line-height: 40px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
}

@media only screen and (min-width: 769px) {
  .text-input {
    margin-left: 1px;
    margin-right: -3px;
  }
}

.infants-summary {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
