.back-to-list-arrow {
  width: 11px;
  height: auto;
  margin-right: 7px;
  position: relative;
  top: 6px;
}
.back-to-list-wrapper {
  position: fixed;
  margin-bottom: 30px;
  left: 30px;
  cursor: pointer;
  z-index: 1;
}
.back-to-list-link {
  text-decoration: none;
}
.back-to-list-text {
  text-decoration: none;
  color: #287fb8;
  font-size: 1.1em;
  font-weight: 600;
}
@media (max-width: 1550px) {
  .back-to-list-wrapper {
    position: relative;
    left: 0;
    margin-bottom: 15px;
    margin-top: -9px;
    text-align: left;
    padding: 8px 0 12px 0;
    transition: 0.3s;
    a {
      display: block;
    }
    img {
      margin-right: 15px;
    }
  }
}
@media (max-width: 992px) {
  .back-to-list-wrapper {
    position: relative;
  }
}
