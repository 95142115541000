body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.slider {
  width: 100%;
  max-width: 470px;
  position: relative;
  float: left;
  left: 50%;
  transform: translateX(-50%);
  top: -11px;
}

.handle {
  font-size: 0.9em;
  text-align: center;
  background-color: #003b79;
  color: white;
  cursor: pointer;
}

.handle.active {
  background-color: #004ea6;
}

.sz-employee-bar {
  position: relative;
  background: #FF5200;
  border-radius: 20px;
}

.sz-employee-bar.employee-bar-1 {
  background: #FF5200;
}

.sz-employee-bar.employee-bar-2 {
  background: #FF5200;
}

.employee-bar {
  position: relative;
  background: #afc836;
  border-radius: 20px;
}

.employee-bar.employee-bar-1 {
  background: #afc836;
}

.employee-bar.employee-bar-2 {
  background: #afc836;
}

.slider .employee-bar {
  top: 22px;
  height: 5px;
}

.slider .sz-employee-bar {
  top: 22px;
  height: 5px;
}


.bar {
  position: relative;
  background: #fecd25;
  border-radius: 20px;
}

.bar.bar-1 {
  background: #fecd25;
}

.bar.bar-2 {
  background: #fecd25;
}

.slider .bar {
  top: 22px;
  height: 5px;
}

.slider .handle {
  top: 15px;
  width: 20px;
  height: 20px;
  line-height: 48px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

#horizontal-0,
#horizontal-1,
#horizontal-2,
#horizontal-3 {
  margin: 20px 10px;
}

.react-tabs [role='tab'][aria-selected='true'] {
  background-color: #eb990f !important;
  color: #fff;
}
@media (min-width: 1150px) and (max-width: 1550px) {
  .slider {
    max-width: 300px;
  }
}
@media (min-width: 1024px) and (max-width: 1150px) {
  .slider {
    max-width: 200px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .slider {
    max-width: 300px;
  }
}
@media (max-width: 768px) {
  .slider {
    max-width: 400px;
    display: block;
    top: -16px;
  }
}
@media (max-width: 620px) {
  .slider {
    max-width: 250px;
  }
}
