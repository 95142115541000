.detail-map {
  width: 100%;
  height: 500px;
}

.smap .card {
  margin-bottom: 45px !important;
}

.map-overlay {
  z-index: 999;
}
