.arrow-button {
  cursor: pointer;
  margin-top: 10px;
  font-size: 35px;
  float: left;
  color: #8b8d8e;
}

.gallery-wrapper {
  display: flex;
}

.image {
  height: 250px;
  width: 250px;
}

button.arrow-button:hover {
  color: #f80;
}

.swipeable-date-wrapper {
  max-width: 150px;
}

button {
  background-color: transparent;
  outline: none;
  border: transparent;
}

@media only screen and (max-width: 1000px) {
  .arrow-button {
    margin-top: 5px;
    font-size: 30px;
  }
}
