.comparement-side-bar {
  right: 0;
  top: 250px;
  position: fixed;
  display: flex;
  background-color: rgb(56, 102, 150);
  height: 250px;
  width: 320px;
}

.comparement-open-wrapper {
  width: 45px;
  cursor: pointer;
}

.comparement-open-wrapper i {
  transition: 0.3s;
}

.comparement-bottom-bar {
  text-align: center;
  max-width: 150px;
  margin: 0 auto;
}

.comparement-open-title {
  position: absolute;
  transform: rotate(-90deg);
  left: -54px;
  color: #fff;
  font-weight: 400;
  bottom: 83px;
  font-size: 1.1em;
  text-transform: uppercase;
}

.comparement-info {
  padding: 3px 0 10px 0;
  background-color: #f2f2f2;
  color: rgb(56, 102, 150);
  width: 275px;
}

.comparement-tours {
  height: 195px;
}

.comparement-hotel {
  display: flex;
  padding: 4px 4px 4px 8px;
  border-bottom: 1px solid #dfdfdf;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.comparement-close {
  color: red;
  float: right;
  width: 27px;
  text-align: right;
  padding-right: 0;
  margin-left: 4px;
  margin-top: 3px;
  border-left: 1px solid #ddd;
  cursor: pointer;
}

.comparement-side-bar.shown {
  animation: show-comparement-side-bar ease 0.5s alternate;
  animation-fill-mode: forwards;
}

.comparement-side-bar.closed {
  animation: close-comparement-side-bar ease 0.5s alternate;
  animation-fill-mode: forwards;
}

.comparement-side-bar.hidden {
  animation: hide-comparement-side-bar ease 0.5s alternate;
  animation-fill-mode: forwards;
}

.comparement-side-bar.opened {
  animation: open-comparement-side-bar ease 0.5s alternate;
  animation-fill-mode: forwards;
}

@keyframes show-comparement-side-bar {
  0% {
    right: -325px;
  }
  100% {
    right: -275px;
  }
}

@keyframes close-comparement-side-bar {
  0% {
    right: 0px;
  }
  100% {
    right: -275px;
  }
}

@keyframes open-comparement-side-bar {
  0% {
    right: -275px;
  }
  100% {
    right: 0px;
  }
}

@keyframes hide-comparement-side-bar {
  0% {
    right: -275px;
  }
  100% {
    right: -325px;
  }
}

.open-comparement {
  cursor: pointer;
  padding: 5px;
  color: white;
  font-size: 35px !important;
}
