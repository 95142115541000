.lastminute-button {
  width: 100% !important;
  max-width: 130px;
}

.lastminute-button:hover {
  opacity: 0.9;
  background-color: #386696 !important;
  color: #fff !important;
  border-color: #386696 !important;
}

#map {
  height: 540px;
}

#map .card {
  width: 300px;
  padding: 0;
  border-radius: 0;
}

#map .card-body {
  overflow: hidden;
}

#map .card .gallery-wrapper {
  position: relative;
  height: 200px;
}

#map .card .gallery-wrapper .image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#map .card .gallery-wrapper button {
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffffcf;
  z-index: 999;
}

#map .card .gallery-wrapper #next-right {
  right: -5px;
  left: inherit;
}

#map .card .close {
  top: 4px;
  right: 4px;
}

#map .card .close:after {
  color: #fff;
}

#map .card .description-box {
  padding: 10px 15px 15px;
}

#map .card .description-box a:after {
  content: '';
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 500px;
  height: 500px;
}

.filters-header {
  background-image: url(/img/bcg-tours.jpg);
}

.tour-filteres-wrapper {
  max-width: 900px;
  margin: 0 auto;
  padding: 80px 40px 40px;
  padding-bottom: 50px;
  
  @media only screen and (max-width: 768px) {
    .tour-filteres-wrapper {

    }
  }
}

.tours-list-filter-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.col-4 {
  width: calc(33.33% - 10px);
  margin: 0 5px;
}

.col-3 {
  width: calc(25% - 10px);
  margin: 0 5px;
}

@media only screen and (max-width: 768px) {
  .tour-filteres-wrapper {
    padding: 60px 15px 45px 15px;
  }

  .tour-liset-box-wrapper {
    position: relative !important;
    left: 50% !important;
    z-index: 9;
    transform: translateX(-50%) !important;
  }

  .col-3,
  .col-4 {
    width: 100%;
  }
}
