.hotel-pdf-description {
  visibility: hidden;
  position: fixed;
  left: -50000;
  top: -50000;
  width: 400px;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px;
  line-height: 19px;
}
