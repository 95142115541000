.button.big {
  padding: 20px;
  position: relative;
  font-size: 1rem;
  background-color: white;
  color: $blue;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  width: 210px;
  font-weight: 600;
  transition: 100ms;

  &:before {
    content: "";
    left: 0;
    height: 100%;
    top: 0;
    border: 0;
    width: 12px;
    border-radius: 5px 0 0 5px;
    position: absolute;
    transition: 200ms;
  }

  &:hover {

    &:before {
      width: 20px;
    }

  }


  &.user {
    &:before {
      background-color: $orange;
    }
  }

  &.employee-1 {
    &:before {
      background-color: $green;
    }
  }

  &.employee-2 {
    &:before {
      background-color: $orangeDark;
    }
  }


}