.tour-list-wrapper {
  max-width: 1170px;
  margin: 0 auto;
  padding: 50px 30px;
}

@media only screen and (max-width: 500px) {
  .tour-list-wrapper {
    padding: 0 15px;
  }
}
