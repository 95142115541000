.comparement-wrapper {
  padding: 25px;
  text-align: center;
}

.comparement-page-title {
  color: #386696;
}

.comparement-occupation {
  display: flex;
}
.comparement-table-scrollbox {
  width: 100%;
  overflow: auto;
}

.comparement-table {
  border-collapse: collapse;
  background: #fafafa;
  min-width: 700px;
  margin: 20px auto 50px auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.comparement-column-header {
  color: rgb(237, 183, 0) !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  text-decoration: none;
}

.comparement-column {
  padding: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: rgba(89, 89, 89, 0.15) 1px solid;
}

.comparement-table tr td:nth-child(2) {
  border-left: none;
}

.comparement-icon-wrapper {
  display: flex;
  justify-content: center;
}

.comparement-icon {
  padding: 0 5px;
  height: 30px;
}

.comparement-table-title {
  padding: 10px;
  border-bottom: rgba(0, 0, 0, 0.3) 1px solid;
  background-color: #386696;
  text-align: left;
  color: white;
  text-transform: uppercase;
}

.check {
  margin-left: 10px;
  color: greenyellow;
}

.close {
  margin-left: 10px;
  color: red;
}
